/* markdown-beautiful.css */

/* 全局 Markdown 容器样式 */
.markdown-container {
  font-family: 'Georgia', serif;
  line-height: 1.6;
  color: #333;
  margin: 0 auto;
  padding: 20px;
}

/* 标题样式 */
.markdown-container h1 {
  font-size: 2.5em;
  color: #1a202c; /* 深灰色 */
  border-bottom: 2px solid #e2e8f0; /* 底部边框 */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.markdown-container h2 {
  font-size: 2em;
  color: #2d3748; /* 稍浅灰色 */
  border-bottom: 1px solid #e2e8f0; /* 底部边框 */
  padding-bottom: 8px;
  margin-bottom: 18px;
}

.markdown-container h3 {
  font-size: 1.75em;
  color: #4a5568; /* 更浅灰色 */
  margin-bottom: 16px;
}

.markdown-container h4 {
  font-size: 1.5em;
  color: #4a5568;
  margin-bottom: 14px;
}

.markdown-container h5 {
  font-size: 1.25em;
  color: #4a5568;
  margin-bottom: 12px;
}

.markdown-container h6 {
  font-size: 1em;
  color: #4a5568;
  margin-bottom: 10px;
}

/* 段落样式 */
.markdown-container p {
  margin-bottom: 1em;
  color: #4a5568;
}

/* 列表样式 */
.markdown-container ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 1em;
}

.markdown-container ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 1em;
}

.markdown-container li {
  margin-bottom: 0.5em;
  color: #4a5568;
}

/* 引用样式 */
.markdown-container blockquote {
  border-left: 4px solid #cbd5e0;
  padding-left: 16px;
  margin: 1em 0;
  color: #2d3748;
  font-style: italic;
  background-color: #f7fafc;
}

/* 强调和加粗样式 */
.markdown-container strong {
  font-weight: 700; /* 加粗 */
  color: #2b6cb0; /* 深蓝色 */
}

.markdown-container em {
  font-style: italic;
  color: #d53f8c; /* 粉红色 */
}

/* 链接样式 */
.markdown-container a {
  color: #3182ce;
  text-decoration: none;
  border-bottom: 1px solid #3182ce;
}

.markdown-container a:hover {
  text-decoration: underline;
  color: #2b6cb0;
}

/* 图片样式 */
.markdown-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px; /* 图片圆角 */
}

/* 代码样式 */
.markdown-container code {
  background-color: #f7fafc;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
  color: #c53030; /* 红色 */
}

.markdown-container pre {
  background-color: #f7fafc;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  white-space: pre-wrap;
  color: #4a5568;
  border: 1px solid #e2e8f0; /* 边框 */
}

/* 表格样式 */
.markdown-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown-container th,
.markdown-container td {
  border: 1px solid #e2e8f0;
  padding: 0.75em;
  text-align: left;
}

.markdown-container th {
  background-color: #f0f4f8;
  color: #2d3748;
}

.markdown-container td {
  background-color: #ffffff;
}

/* 自定义代码块样式 */
.markdown-container pre code {
  background: none;
  border: none;
  padding: 0;
  font-size: inherit;
  color: inherit;
}
